import Web3 from 'web3'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import nftABI from './nftABI.json'
import BN from 'bignumber.js'

let connected = false

const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            infuraId: '86e7e6a20ae7477ba9894b5ab356db3f'
        }
    }
}

const networks = {
    mainnet: {
        nft: '0x506543b7f2dce30e235714446dc9bd634efae8a1',
    },
    rinkeby: {
        nft: '0x506543b7f2dce30e235714446dc9bd634efae8a1'
    },
}

const network = typeof window !== 'undefined' && window.location.host.toLowerCase().indexOf('localhost') === -1 
    ? 'mainnet' : 'rinkeby'
export const alchemy = new Web3.providers.WebsocketProvider(`wss://eth-${network}.alchemyapi.io/v2/UVshVfN22qgd9_HyuS1_n8Kunf9Bfuc2`)
export const web3 = new Web3(alchemy)
export const nft = new web3.eth.Contract(nftABI, networks[network].nft)

export const web3Modal = new Web3Modal({
    network,
    cacheProvider: false,
    providerOptions
})

export default web3

export async function getMintDetails() {
    const [supply] = await Promise.all([
        nft.methods.totalSupply().call().then(x => parseInt(x)),
    ])

    return { supply }
}

export async function getNfts(danils) {
    const [from] = await web3.eth.getAccounts()
    const tokenIds = Array.from(await nft.methods.walletOfOwner(web3.utils.toChecksumAddress(
        danils ? '0xa5855f4F40a409fFDe3a171eB616926AEc5D7B9C' : from
    )).call())

    let tokens = await Promise.all(
        tokenIds
        .map(tokenId =>
            fetch(`https://moonwalk.mypinata.cloud/ipfs/QmQrvNdBmQkhDG1EVDqJJXJroFCsQhKURMkstX3HPi3MV7/${tokenId}`)
                .then(res => res.json())
        )
    )

    return tokens
}

export async function stake(selected, callback) {
    const [from] = await web3.eth.getAccounts()

    await stakingV2.methods.deposit(selected).send({ from }, (err, tx) => {
        if (err) console.log(err)
        callback(err, tx)
    })
}

export async function unstake(selected, callback) {
    const [from] = await web3.eth.getAccounts()
    const depositsInOldContract = await stakingV1.methods.depositsOf(from).call()
    const selectedOld = [];
    const selectedNew = [];
    for (let i = 0; i < selected.length; i++) {
        const cur = selected[i];
        if(depositsInOldContract.includes(cur)) {
            selectedOld.push(cur);
        }
        else {
            selectedNew.push(cur);
        }
    }
    if(selectedOld.length > 0 && selectedNew.length > 0) {
        // Disallow this, as they would need to make 2 transactions.
        alert("You have selected farms from both the old contract and new contract. You must withdraw farms from the old contract separately.");
    }
    else if(selectedNew.length > 0){
        await stakingV2.methods.withdraw(selectedNew).send({ from }, (err, tx) => {
            if (err) console.log(err)
            callback(err, tx)
        });
    }
    else if(selectedOld.length > 0){
        // Must prompt the user that 2 transactions need to happen (1 to claim rewards, then the next to withdraw)
        var accept = confirm("This farm is on the old staking contract. Rewards must be claimed before withdrawing or they will be lost forever. Press OK to be asked if you want to claim your rewards and continue with your withdraw.");
        if(!accept) {
            callback('user cancelled the withdraw');
            return;
        }
        var acceptWithdraw = confirm("Would you like to claim your $CROP before withdrawing the farm(s)? The $CROP will be lost forever if you withdraw without claiming.");
        if(acceptWithdraw) {
            await claim(selectedOld, null);
        }
        await stakingV1.methods.withdraw(selectedOld).send({ from }, (err, tx) => {
            if (err) console.log(err)
            callback(err, tx)
        });
    }
}

window.farm = { getNfts }

export async function mint(mintAmount, price, callback) {
    const [from] = await web3.eth.getAccounts()

    return nft.methods.mint(mintAmount).send({
        from,
        gas: 320000 * mintAmount,
        value: (new BN(price)).multipliedBy(mintAmount).toString(),
    }, (err, tx) => {
        callback(err, tx)
    })
}
export function isConnected() {
    return connected
}

export async function connect(afterConnect, afterDisconnect) {
    if (isConnected()) return

    const providerNew = await web3Modal.connect()
    web3.setProvider(providerNew)

    const accs = await web3.eth.getAccounts()

    if (accs.length > 0) {
        connected = true
        await afterConnect()
    }

    providerNew.on('accountsChanged', (info) => {
        afterConnect()
    });

    providerNew.on('connect', (info) => {
        connected = true
        afterConnect()
    });

    providerNew.on('disconnect', (error) => {
        connected = false
        afterDisconnect()
    });
}

if (typeof window !== 'undefined') {
    window.web3 = web3
    window.nft = nft
}