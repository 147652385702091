import web3, {
  connect,
  getNfts,
  isConnected,
  mint,
  getMintDetails,
} from "./web3";
import confetti from "canvas-confetti";
import { BigNumber } from "bignumber.js";
import MagicWand from "magic-wand-tool";

require("./jquery");

const $ = typeof window !== "undefined" && window.jQuery;
let mintAmount = 1;
let maxAmount = 10;
let remainingNfts = 0;
let allSkyDivs = [];
let imageInfo;
let imageMask;
let imageCvs;
let lastTxHash;

let $mintButton = $(".mint-button");
let $mintButtonMax = $(".mint-button-max");
let $totalSkies = $(".total-skies-value");
let $cropMint = $(".crop-mint");
let $downloadButton = $(".download-button");
let $pfpFileContainer = $("#pfp-file-container");
let $selectedPreview = $("#selected-pfp-preview");
let $pfpEditor = $("#editor");
let $selectedPreviewBg = $("#selected-preview-bg");
let $skiesScroller = $("#skies-scroller");
let $mintAmountEl = $(".mint-button > div > span");
let $minusButton = $(".minus-button");
let $plusButton = $(".plus-button");

async function selectActiveSky(current) {
  allSkyDivs.forEach((sky) => sky.removeClass("carousel-active"));
  current.addClass("carousel-active");
  $selectedPreviewBg
    .attr("src", current.find("img:first-child").attr("src"))
    .show();
  $selectedPreview.show();
}

async function updateNfts(danil) {
  let nfts = await getNfts(danil);
  $totalSkies.html(nfts.length);

  if (nfts.length !== 0) {
    $(".if-skies").show();
    $(".no-skies").hide();
  } else {
    $(".if-skies").hide();
    $(".no-skies").show();
  }

  allSkyDivs = [];
  $skiesScroller.html("");

  nfts.forEach((nft) => {
    const imgSrc = `${nft.image}?img-width=200&img-height=160`;
    const $img = $("<img />")
      .addClass("carousel-cell-image")
      .attr("crossorigin", "anonymous")
      .attr("src", imgSrc)
      .attr("width", "100%")
      .attr("height", "100%");
    const $div = $("<a />")
      .attr("href", "#editor")
      .addClass("carousel-cell")
      .on("click", () => selectActiveSky($div))
      .append($img);
    $skiesScroller.append($div);
    allSkyDivs.push($div);
  });

  if (allSkyDivs.length !== 0) {
    selectActiveSky(allSkyDivs[0]);
  }
}

async function afterConnect() {
  const accs = await web3.eth.getAccounts();
  jQuery(".connect-btn").hide();
  jQuery(".if-not-connected").hide();
  jQuery(".if-connected").show();
  jQuery(".logged-in-btn > .text-block").html(
    `${accs[0].slice(0, 5)}...${accs[0].slice(-5)}`
  );
  jQuery(".logged-in-btn").show();
  await updateNfts();
}

async function afterDisconnect() {
  jQuery(".connect-btn").show();
  jQuery(".if-not-connected").show();
  jQuery(".if-connected").hide();
  jQuery(".logged-in-btn").hide();
  nfts = null;
  nftsDeposited = null;
}

function celebrate() {
  const duration = 5000;
  const animationEnd = Date.now() + duration;
  const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  const interval = setInterval(function () {
    const timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    const particleCount = 50 * (timeLeft / duration);
    // since particles fall down, start a bit higher than random
    confetti(
      Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
      })
    );
    confetti(
      Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
      })
    );
  }, 250);
}

function updateMintAmount(amount) {
  mintAmount = amount;
  $mintAmountEl.html(mintAmount);
  $minusButton.removeClass("disabled");
  $plusButton.removeClass("disabled");
  if (mintAmount === maxAmount) {
    $plusButton.addClass("disabled");
  } else if (mintAmount === 1) {
    $minusButton.addClass("disabled");
  }
}

function hexToRgb(hex, alpha) {
  var int = parseInt(hex, 16);
  var r = (int >> 16) & 255;
  var g = (int >> 8) & 255;
  var b = int & 255;

  return [r, g, b, Math.round(alpha * 255)];
}

function downloadImages($pfpImg, $bgImg) {
    const pfpimg = $pfpImg[0]
    const bgimg = document.createElement('img')
    bgimg.crossOrigin = 'anonymous';
    bgimg.src = $bgImg[0].src.split('?')[0];

    setTimeout(() => celebrate(), 1000)

    bgimg.onload = () => {
        const cvs = document.createElement('canvas').getContext('2d')
        cvs.canvas.width = imageInfo.width
        cvs.canvas.height = imageInfo.height
    
        cvs.drawImage(bgimg, 0, 0, imageInfo.width, imageInfo.height)
        cvs.drawImage(pfpimg, 0, 0, imageInfo.width, imageInfo.height)

        const link = document.createElement('a');
        link.download = 'sky pfp.png';
        link.href = cvs.canvas.toDataURL()
        link.click()
    }

}

function handleImageFill(img) {
  let rgba = hexToRgb("FF0000", 0.35);

  const tmpCvs = document.createElement("canvas").getContext("2d");
  imageCvs = document.createElement("canvas");
  imageCvs.width = img.width;
  imageCvs.height = img.height;

  imageInfo = {
    width: img.width,
    height: img.height,
    context: document.createElement("canvas").getContext("2d"),
  };

  tmpCvs.canvas.width = imageInfo.width;
  tmpCvs.canvas.height = imageInfo.height;
  tmpCvs.drawImage(img, 0, 0);
  imageInfo.context.drawImage(img, 0, 0);
  imageInfo.data = tmpCvs.getImageData(0, 0, imageInfo.width, imageInfo.height);

  imageMask = MagicWand.floodFill(
    {
      width: imageInfo.width,
      height: imageInfo.height,
      data: imageInfo.data.data,
      bytes: 4,
    },
    Math.floor(imageInfo.width * 0.02),
    Math.floor(imageInfo.height * 0.02),
    8,
    null,
    true
  );

  imageMask = MagicWand.gaussBlurOnlyBorder(imageMask, 5);

  let x,
    y,
    k,
    data = imageMask.data,
    bounds = imageMask.bounds,
    maskW = imageMask.width,
    w = imageInfo.width,
    h = imageInfo.height,
    imgData = imageInfo.context.createImageData(w, h),
    curData = imageInfo.data.data,
    res = imgData.data;

  for (y = bounds.minY; y <= bounds.maxY; y++) {
    for (x = bounds.minX; x <= bounds.maxX; x++) {
      if (data[y * maskW + x] != 0) continue;
      k = (y * w + x) * 4;
      res[k] = curData[k];
      res[k + 1] = curData[k + 1];
      res[k + 2] = curData[k + 2];
      res[k + 3] = curData[k + 3];
    }
  }

  tmpCvs.putImageData(imgData, 0, 0);
  $selectedPreview.attr("src", tmpCvs.canvas.toDataURL());
}

function handleFileUpload(file) {
  const reader = new FileReader();
  reader.onload = function (e) {
    let img = document.createElement("img");
    img.setAttribute("src", e.target.result);
    img.onload = () => {
      requestAnimationFrame(() => handleImageFill(img));
    };
  };
  reader.readAsDataURL(file);
  $pfpEditor.addClass("with-image-preview");
  if (!isConnected()) {
    connect(afterConnect, afterDisconnect);
  }
}

function initialize() {
  jQuery(".if-not-connected").show();

  getMintDetails()
    .then(async ({ supply }) => {
      $(".total-minted").html(supply.toLocaleString());
    })
    .catch((err) => {
      console.error(err);
      $(".mint-btn-container").hide();
      $(".minting-open-display").html("Error Connecting");
    });

  $(".try-danils-nfts").on("click", () => {
    updateNfts(true).catch((e) => console.log(e));
  });

  $downloadButton.on("click", () => {
    downloadImages($selectedPreview, $selectedPreviewBg)
  })

  $plusButton.on("click", (e) => {
    e.preventDefault();
    updateMintAmount(Math.min(mintAmount + 1, 10));
  });

  $minusButton.on("click", (e) => {
    e.preventDefault();
    updateMintAmount(Math.max(mintAmount - 1, 1));
  });

  $(".close-link").on("click", (e) => {
    $(".header-notification").hide();
  });

  $(".connect-btn").on("click", () => {
    connect(afterConnect, afterDisconnect);
  });

  $mintButton.on("click", async (e) => {
    e.preventDefault();
    let price = "40000000000000000";

    await connect(afterConnect, afterDisconnect);

    await mint(mintAmount, price, (err, tx) => {
      if (err) return console.log(err);
      lastTxHash = tx;
      remainingNfts -= mintAmount;
      $(".mints-remaining").html(remainingNfts.toLocaleString());
      celebrate();
      $(".header-notification").show();
      $(".waifu-count").html(mintAmount);
      $(".transaction-link").attr(
        "href",
        "https://etherscan.io/tx/" + lastTxHash
      );
    });

    // await updateWaifus()
  });

  $mintButtonMax.on("click", async (e) => {
    e.preventDefault();
    let mintAmount = 7;
    let price = "40000000000000000";

    await connect(afterConnect, afterDisconnect);

    await mint(mintAmount, price, (err, tx) => {
      if (err) return console.log(err);
      lastTxHash = tx;
      remainingNfts -= mintAmount;
      $(".mints-remaining").html(remainingNfts.toLocaleString());
      celebrate();
      $(".header-notification").show();
      $(".waifu-count").html(mintAmount);
      $(".transaction-link").attr(
        "href",
        "https://etherscan.io/tx/" + lastTxHash
      );
    });

    // await updateWaifus()
  });

  $cropMint.on("click", async (e) => {
    e.preventDefault();

    await connect(afterConnect, afterDisconnect);
  });

  $(".pfp-upload-btn").on("click", () => {
    $pfpFileContainer.trigger("click");
  });

  $pfpFileContainer.on("change", (e) => {
    const inp = e.target;

    if (inp.files && inp.files[0]) {
      handleFileUpload(inp.files[0]);
    }
  });

  $pfpEditor.on("dragover", (e) => {
    e.preventDefault();
    $pfpEditor.addClass("pfp-dragging-over");
  });

  $pfpEditor.on("dragend", () => {
    $pfpEditor.removeClass("pfp-dragging-over");
  });

  $pfpEditor.on("drop", (e) => {
    $pfpEditor.removeClass("pfp-dragging-over");
    if (
      e.originalEvent.dataTransfer &&
      e.originalEvent.dataTransfer.files.length
    ) {
      e.preventDefault();
      e.stopPropagation();
      handleFileUpload(e.originalEvent.dataTransfer.files[0]);
    }
  });
}

initialize();
